:root {
  --font-size-scale: 1;
  --big-button-height: 3rem;
  --small-button-height: 2.5rem;
  --page-title-font-size: 1.1rem;
}

.App {
  text-align: center;
  scroll-behavior: smooth;
}

.div-parent-flex-row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: end;
}

.div-parent-flex-column {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.div-50-son {
  width: 48%;
}

.middle-container {
  width: 50%;
}

.side-container {
  width: 25%;
}

.div-width-66 {
  width: 66%;
}

.div-width-100 {
  width: 100%;
}

.tooltip-container {
  position: relative;
}

.tooltip-container .tooltiptext {
  visibility: hidden;
  width: 8rem;
  background-color: var(--div-background);
  color: var(--inside-div-text-color);
  text-align: center;
  border-radius: 6px;
  padding: 0.15rem;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  height: 2rem;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
}

.tooltip-container .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--div-background) transparent;
}

.tooltip-container .tooltipTextInfoEntities {
  visibility: hidden;
  width: 10rem;
  background-color: var(--div-background);
  color: var(--inside-div-text-color);
  text-align: center;
  border-radius: 6px;
  padding: 0.5rem;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 20%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  height: fit-content;
  max-height: 7rem;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.tooltip-container .tooltipTextInfoEntities::after {
  content: "";
  position: absolute;
  bottom: 100%;

  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--div-background) transparent;
}

.tooltip-container:hover .tooltipTextInfoEntities {
  visibility: visible;
  opacity: 1;
}

.tooltip-container:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip-container .tooltiptextIconEdit {
  width: 6rem;
  height: 3.25rem;
  top: 0%;
  left: -410%;
}
.tooltip-container .tooltiptextIconEdit::after {
  content: " ";
  position: absolute;
  top: 25%;
  left: 105%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent var(--div-background);
}

.tooltip-container .tooltipBadge {
  width: 6rem;
  height: 3.25rem;
  top: 0%;
  left: -6.5rem;
  display: flex;
  justify-content: center;
  text-align: center;
}

.tooltip-container .tooltipBadge::after {
  content: " ";
  position: absolute;
  top: 25%;
  left: 105%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent var(--div-background);
}

.skeleton-box {
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background: linear-gradient(
    90deg,
    var(--skeleton-shimmer-color-top-bottom) 0%,
    var(--skeleton-shimmer-color-middle) 50%,
    var(--skeleton-shimmer-color-top-bottom) 100%
  );
  animation: shimmmer 1.75s infinite;
  content: "";
}

.disabled-element {
  cursor: not-allowed;
  opacity: 0.8;
}

.input-field {
  height: 2.75rem;
}

.input-field-outside {
  border: 1px solid var(--outside-div-text-color);
}

.input-label-inside-div {
  color: var(--inside-div-text-color);
  font-weight: 100;
  font-size: 1rem;
  margin-top: 0.5rem !important;
  margin-bottom: 0.25rem !important;
  text-align: start;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.input-label-outside-div {
  color: var(--outside-div-text-color);
  font-weight: 100;
  font-size: 1rem;
  margin-top: 0.5rem !important;
  margin-bottom: 0.25rem !important;
  text-align: start;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

::-webkit-scrollbar {
  width: 0.4rem;
  height: 8px;
  background-color: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: none !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: var(--card-border-radius);
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Input focus blue tint */
.blox-input {
}

.loader {
  width: 7rem !important;
  height: 7rem !important;
  margin: auto;
  font-size: 2rem;
  color: var(--inside-highlight-color);
}

.loader-outside {
  width: 7rem !important;
  height: 7rem !important;
  margin: auto;
  font-size: 2rem;
  color: var(--outside-highlight-color);
}

.loader-send-inside-div-button {
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-right: 0.5rem;
  color: var(--inside-primary-button-text-color);
}

.loader-send-outside-div-button {
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-right: 0.5rem;
  color: var(--outside-primary-button-text-color);
}

.loader-small-outside {
  width: 1.75rem !important;
  height: 1.75rem !important;
  color: var(--outside-div-text-color);
}

.loader-download {
  width: 1.75rem !important;
  height: 1.75rem !important;
  color: var(--outside-div-text-color);
  font-size: calc(1.5rem * var(--font-size-scale)) !important;
}

.inside-primary-button {
  background: var(--inside-primary-button-background) !important;
  color: var(--inside-primary-button-text-color) !important;
  border: var(--inside-primary-button-button-border) !important;
  font-weight: var(--button-font-weight) !important;
  border-radius: var(--button-border-radius);
  transition: none !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: calc(0.9rem * var(--font-size-scale)) !important;
}

.inside-primary-button:hover {
}

.inside-secondary-button {
  background: var(--inside-secondary-button-background) !important;
  color: var(--inside-secondary-button-text-color) !important;
  border: var(--inside-secondary-button-button-border) !important;
  font-weight: bold !important;
  transition: none !important;
  font-size: calc(0.9rem * var(--font-size-scale)) !important;
}

.inside-secondary-button:hover {
}

.outside-primary-button {
  background: var(--outside-primary-button-background) !important;
  color: var(--outside-primary-button-text-color) !important;
  border: var(--outside-primary-button-button-border) !important;
  font-weight: var(--button-font-weight) !important;
  border-radius: var(--button-border-radius) !important;
  transition: none !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: calc(0.9rem * var(--font-size-scale)) !important;
}

.outside-primary-button:hover {
}

.outside-secondary-button {
  background: var(--outside-secondary-button-background) !important;
  color: var(--outside-secondary-button-text-color) !important;
  border: var(--outside-secondary-button-button-border) !important;
  font-weight: bold !important;
  transition: none !important;
  font-size: calc(0.9rem * var(--font-size-scale)) !important;
}

.inside-danger-button {
  background: none !important;
  color: var(--inside-danger-color) !important;
  border: var(--outside-secondary-button-button-border) !important;
  font-weight: bold !important;
  transition: none !important;
}

.outside-danger-button {
  background: none !important;
  color: var(--outside-danger-color) !important;
  border: 1px solid var(--outside-danger-color) !important;
  font-weight: bold !important;
  transition: none !important;
  font-size: calc(0.9rem * var(--font-size-scale)) !important;
  height: var(--small-button-height);
}

.outside-danger-button:hover {
  background: var(--outside-danger-color) !important;
  color: var(--inside-div-text-color) !important;
  border: 1px solid var(--outside-danger-color) !important;
}

.inside-selected-div-button {
  border: 1px var(--selected-text-color) solid !important;
}

.selected-button {
  font-weight: bold !important;
  border-width: 2px !important;
}

.buttons-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
}

.buttons-div .primary-button {
  margin-top: 1.5rem;
  width: 100%;
  height: var(--big-button-height);
  border-radius: var(--button-border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons-div .secondary-button {
  margin-top: 0.5rem;
  width: 50%;
  height: var(--big-button-height);
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondary-buttons-div {
  width: 100%;
  display: flex;
}

.small-button {
  height: var(--small-button-height) !important;
  min-width: 5rem;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.link-button-inside {
  background: var(--inside-primary-button-background) !important;
  color: var(--inside-primary-button-text-color) !important;
  border: var(--inside-primary-button-button-border) !important;
  font-size: calc(0.9rem * var(--font-size-scale)) !important;
  font-weight: var(--button-font-weight) !important;
  border-radius: var(--button-border-radius);
  transition: none !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  text-decoration: none;
}

.link-inside {
  background: none !important;
  color: var(--inside-highlight-color) !important;
  border: none !important;
  font-weight: bold !important;
  transition: none !important;
  text-decoration: none;
  cursor: pointer;
}

.link-outside {
  background: none !important;
  color: var(--outside-highlight-color) !important;
  border: none !important;
  font-weight: bold !important;
  transition: none !important;
  cursor: pointer;
}

.closer-buttons-div {
  display: flex;
  flex-direction: row-reverse;
}

.error-form-text {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  color: crimson;
  font-weight: bold;
  font-size: calc(0.9rem * var(--font-size-scale)) !important;
  line-height: 1rem;
  text-align: center;
  animation: bounce 0.5s;
}

.error-form-text-filler {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  height: 1px;
  line-height: 1rem;
}

.success {
  color: var(--inside-success-color);
}

.danger {
  color: var(--inside-danger-color);
}

.danger {
  color: var(--inside-danger-color);
}

.dull {
  color: var(--inside-dull-color);
}

.success-background {
  background: var(--inside-success-color);
}

.danger-background {
  background: var(--inside-danger-color);
}

.pending-background {
  background: var(--inside-pending-color);
}

.version-number {
  margin-top: 1.3rem;
  margin-bottom: 0px;
  font-size: 0.9rem;
  text-align: center;
  font-style: italic;
}

/* React code input to hide wheel for number type */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.fade-in-up {
  animation: fade-in-up 0.6s;
}

.cursor-pointer {
  cursor: pointer;
}

.primary-modal .modal-title {
  font-size: calc(
    var(--page-title-font-size) * var(--font-size-scale)
  ) !important;
}

.primary-modal .modal-content {
  background-color: var(--modal-background);
  color: var(--modal-text-color);
  border-radius: var(--modal-border-radius);
  font-size: calc(0.9rem * var(--font-size-scale));
}

.primary-modal .modal-content .modal-header {
  border: none;
}

.primary-modal .modal-content .modal-footer {
  border: none;
}

.primary-modal .modal-content .btn-close {
  background-image: var(--modal-icon-close);
}

.primary-modal .modal-content .btn-close:focus {
  box-shadow: none;
  opacity: none;
}

.primary-modal .input-field-modal {
  border: 1px solid var(--modal-text-color);
}

label {
  font-size: calc(0.9rem * var(--font-size-scale)) !important;
}

.form-select {
  font-size: calc(0.9rem * var(--font-size-scale)) !important;
}

.form-control {
  font-size: calc(0.9rem * var(--font-size-scale)) !important;
}

.theme-region-modal .region-list {
  display: flex;
  flex-direction: column;
}

.theme-region-modal .region-list .region-row {
  margin-bottom: 0.5rem;
  height: 3rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--div-border-radius);
  transition: all 0.15s ease-in-out;
}

@media only screen and (max-width: 1600px) {
}

@media only screen and (max-width: 1500px) {
  :root {
    --font-size-scale: 0.975;
  }

  .side-container {
  }

  .middle-container {
  }
}

@media only screen and (max-width: 1400px) {
  :root {
    --font-size-scale: 0.95;
  }
}

@media only screen and (max-width: 1300px) {
  :root {
    --font-size-scale: 0.9;
  }
}

@media only screen and (max-width: 1200px) {
  :root {
    --font-size-scale: 1;
  }

  .side-container {
    display: none;
  }

  .middle-container {
    width: 100%;
  }

  .container-padding {
    padding: 0rem 4rem;
  }

  .buttons-div {
  }
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: var(--search-bar-delete-text-icon) no-repeat 50% 50%;
}

@media only screen and (max-width: 900px) {
  .container-padding {
    padding: 0rem 2rem;
  }
}

@media only screen and (max-width: 600px) {
  :root {
    --font-size-scale: 0.975;
  }

  .container-padding {
    padding: 0rem 0.75rem;
  }

  .buttons-div {
    margin-top: 0rem;
  }
}

@media only screen and (max-width: 400px) {
  :root {
    --font-size-scale: 0.95;
  }
}

@media only screen and (max-width: 320px) {
  :root {
    --font-size-scale: 0.9;
  }
}

@keyframes shimmmer {
  100% {
    transform: translateX(100%);
  }
}

@keyframes vertical-scroll {
  0% {
    top: 0;
  }
  25% {
    top: -100%;
  }
  50% {
    top: -2.4em;
  }
  75% {
    top: -3.6em;
  }
}

@keyframes animate-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-in-up {
  0% {
    transform: translate(0px, 100px);
    opacity: 0;
  }
  100% {
    transform: translate(0px, 0);
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
    font-weight: bold;
  }
  37% {
    transform: translateX(5px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-5px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(4px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-4px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(2px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-2px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
    font-weight: bold;
  }
}
