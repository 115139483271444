:root {
  --total-balance-div: 10rem;
  --total-balance-icons-height: 2.5rem;
  --total-balance-icons-font-size: 1.25rem;
}

.total-balance-container-mask {
  width: 100%;
}

.total-balance-container-mask-pinned {
  width: inherit !important;
}

.total-balance-container {
  width: 100%;
  z-index: 100;
}

.total-balance-icons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: var(--outside-highlight-color);
  padding: 0 1rem 1rem 1rem;
  height: var(--total-balance-icons-height);
}

.total-balance-icons .pin {
  cursor: pointer;
  font-size: 1.25rem;
  transition: font-size 0.5s;
}

.total-balance-icons .hide {
  cursor: pointer;
  font-size: var(--total-balance-icons-font-size);
  animation: fade-in 0.25s;
}

.total-balance-icons .isPinned {
  font-size: calc(var(--total-balance-icons-font-size) - 0.1rem);
}

.total-balance-icons .isHidden {
}

.total-balance-div {
  width: 100%;
  height: var(--total-balance-div);
  border-radius: var(--div-border-radius);
  color: var(--inside-div-text-color);
  border: var(--div-border);
  position: relative;
}

.total-balance-div-pinned {
  position: fixed !important;
  width: inherit;
}

.total-balance-div .background {
  width: 100%;
  height: 100%;
  background-color: var(--div-background);
  position: absolute;
  opacity: var(--div-opacity);
  z-index: 0;
  border-radius: var(--div-border-radius);
}

.total-balance-div .content {
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  border-radius: var(--div-border-radius);
  align-items: center;
}

.total-balance-div .info-column {
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: start;
}

.total-balance-div .info-column .user-photo {
  width: 5rem;
  height: 5rem;
}

.total-balance-div .info-column .image {
  cursor: pointer;
  width: var(--image-width);
  aspect-ratio: 0.8888;
  background-color: var(--inside-highlight-color);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.total-balance-div .info-column .text {
  display: flex;
  flex-direction: column;
  margin-left: 0.75rem;
  align-items: start;
}

.total-balance-div .info-column .text .total-balance-title {
  font-size: calc(1.05rem * var(--font-size-scale));
  font-weight: var(--font-weight-3);
}

.total-balance-div .info-column .text .username {
  font-size: calc(1rem * var(--font-size-scale));
  font-weight: var(--font-weight-2);
  opacity: var(--opacity-2);
}

.total-balance-div .info-column .text .region {
  font-size: calc(0.85rem * var(--font-size-scale));
  font-style: italic;
  font-weight: var(--font-weight-2);
  opacity: var(--opacity-2);
}

.total-balance-div .total-column {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: end;
  justify-content: center;
  position: relative;
}

.total-balance-div .total-column .total {
  font-size: calc(1.05rem * var(--font-size-scale));
  margin-right: 1.5rem;
}

.total-balance-div .total-column .number {
  width: 100%;
  font-size: calc(1rem * var(--font-size-scale));
  font-weight: 400;
  opacity: 1;
  transition: opacity 0.3s;
  display: flex;
  justify-content: space-between;
}

.total-balance-div .total-column .total-number {
  font-weight: var(--font-weight-2);
}

.total-balance-div .total-column .bottom-border {
  border-bottom: 1px solid var(--inside-div-text-color);
}

.total-balance-div .total-column .number-hidden {
  opacity: 0;
  display: flex;
  justify-content: space-between;
}

.total-balance-div .total-column .list-selected-currencies-div {
  font-size: calc(0.8rem * var(--font-size-scale));
  width: 100%;
  margin-top: 0.34rem;
}

.total-balance-div .total-column .list-selected-currencies-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  opacity: var(--opacity-2);
}

.total-balance-div .total-column .list-selected-currencies-row .value {
  text-align: end;
  width: 70%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.total-balance-div .loader-column .loader-total-balance {
  font-size: calc(1rem * var(--font-size-scale));
  margin-top: auto;
  margin-bottom: auto;
  width: 1.75rem !important;
  height: 1.75rem !important;
}

@media only screen and (max-width: 1600px) {
}

@media only screen and (max-width: 1500px) {
}

@media only screen and (max-width: 1400px) {
  :root {
    --total-balance-div: 8rem;
    --total-balance-icons-height: 2.5rem;
    --total-balance-icons-font-size: 1.25rem;
  }

  .total-balance-div .content {
    padding: 0.75rem;
  }

  .total-balance-icons {
    padding: 0 1rem 0.5rem 1rem;
  }
}

@media only screen and (max-width: 1300px) {
  :root {
  }
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
  .total-balance-div .info-column .image {
    display: none;
  }
}

@media only screen and (max-width: 450px) {
  :root {
    --total-balance-div: 10rem;
    --padding-cards: 0.75rem;
    --total-balance-icons-height: 2.5rem;
  }

  .total-balance-icons {
    padding: 1rem 0rem 0.5rem 0rem;
  }

  .total-balance-div {
    width: unset;
    margin: 0.5rem 0rem;
  }

  .total-balance-div .content {
    padding: var(--padding-cards);
  }

  .total-balance-div .info-column {
    width: 77.5%;
  }

  .total-balance-div .info-column .user-photo {
    width: 4rem;
    height: 4rem;
  }

  .total-balance-div .info-column .text {
    width: calc(100% - 4rem - 0.5rem);
  }

  .total-balance-div .info-column .text .title {
    text-align: start;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .total-balance-div .info-column .text .username {
    text-align: start;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .total-balance-div .info-column .text .region {
    text-align: start;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .total-balance-div .total-column {
  }

  .total-balance-div .total-column .total {
  }
}

@media only screen and (max-width: 320px) {
}
