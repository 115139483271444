.pay-me-container {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.pay-me-body {
  width: 100%;
  display: flex;
}

.pay-me-body .title {
  color: var(--outside-div-text-color);
  text-align: start;
  font-size: calc(1.1rem * var(--font-size-scale)) !important;
  font-weight: bold;
  margin-left: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 1rem;
}

.pay-me-body .loading-currencies {
  margin-top: 5rem;
}

.pay-me-body .loading-conversions {
  margin-bottom: 1rem !important;
  width: 2rem !important;
  height: 2rem !important;
}

.pay-me-body .section {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.pay-me-body .section .left-input {
  width: 50%;
  height: fit-content;
  border-radius: var(--div-border-radius);
  border: var(--div-border);
  position: relative;
  animation: fade-in-up 0.6s;
}

.pay-me-body .section .left-input .background {
  background-color: var(--div-background);
  border-radius: var(--div-border-radius);
  opacity: var(--div-opacity);
  width: 100%;
  height: 100%;
  position: absolute;
}

.pay-me-body .section .left-input .content {
  border-radius: var(--div-border-radius);
  width: 100%;
  height: 100%;
  padding: 1.25rem;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pay-me-body .section .left-input .content .form-div {
  height: fit-content;
  width: 100%;
}

.pay-me-body .section .left-input .content .form-div .danger {
  color: var(--inside-danger-color);
}

.pay-me-body .section .left-input .content .message-div {
  color: var(--inside-div-text-color);
  font-style: italic;
  font-size: calc(0.85rem * var(--font-size-scale)) !important;
  margin-top: 1rem;
  flex-grow: 1;
}

.pay-me-body .section .option-div {
  width: 100%;
  padding: 0rem;
  display: flex;
  flex-direction: column;
  color: var(--outside-div-text-color);
  animation: fade-in-up 0.6s;
}

.pay-me-body .section .option-div .sub-title {
  text-align: start;
  font-size: calc(0.9rem * var(--font-size-scale)) !important;
  /* font-style: italic; */
  margin-left: 0.5rem;
  margin-bottom: 1rem;
  display: none;
}

.pay-me-body .section .right-div {
  width: 50%;
  padding: 0rem 1.25rem 0rem 1.25rem;
  display: flex;
  flex-direction: column;
  color: var(--outside-div-text-color);
}

.pay-me-body .section .input-group .text-area {
  margin-bottom: 1rem;
}

.pay-me-body .section .right-div .text-area {
  height: 4rem;
}

.pay-me-body .section .value-input {
  width: 100%;
  height: 100%;
  padding: 1.25rem 0 0 0;
  animation: fade-in-up 0.6s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pay-me-body .loader {
  width: 4rem !important;
  height: 4rem !important;
}

.pay-me-body .error-form-text {
  margin-top: 1.5rem;
  margin-bottom: 0rem;
  color: crimson;
  font-weight: bold;
  font-size: calc(0.9rem * var(--font-size-scale)) !important;
  line-height: 1rem;
  text-align: center;
  animation: bounce 0.5s;
}

.pay-me-body .pay-me-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: var(--div-border-radius);
  align-items: center;
  overflow: auto;
  color: var(--outside-div-text-color);
}

.pay-me-body .pay-me-list ::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.pay-me-body .pay-me-list ::-webkit-scrollbar-thumb {
  background: #888;
}

.pay-me-body .pay-me-list ::-webkit-scrollbar-track {
  background: white;
}

.pay-me-body .pay-me-list .pay-me-row-container {
  width: 100%;
  border-radius: var(--div-border-radius);
  border: var(--div-border);
  cursor: pointer;
  margin-bottom: 1rem;
}

.pay-me-body .pay-me-list .pay-me-row {
  width: 100%;
  height: 6rem;
  border-radius: var(--div-border-radius);
  position: relative;
}

.pay-me-body .pay-me-list .pay-me-row .background {
  width: 100%;
  height: 100%;
  border-radius: var(--div-border-radius);
  background-color: var(--div-background);
  opacity: var(--div-opacity);
  position: absolute;
  z-index: 0;
}

.pay-me-body .pay-me-list .pay-me-row .content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: var(--div-border-radius);
  color: var(--inside-div-text-color);
  position: relative;
  z-index: 1;
}

.pay-me-body .pay-me-list .pay-me-row .metadata-column {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.pay-me-body .pay-me-list .pay-me-row .metadata-column .icon {
  font-size: calc(1.85rem * var(--font-size-scale)) !important;
  margin-right: 0.75rem;
  color: var(--inside-highlight-color);
}

.pay-me-body .pay-me-list .pay-me-row .metadata-column .option-name {
  font-size: calc(0.95rem * var(--font-size-scale)) !important;
  font-weight: var(--font-weight-3);
  text-align: start;
}

.pay-me-body .pay-me-list .pay-me-row .metadata-column .option-description {
  font-size: calc(0.9rem * var(--font-size-scale)) !important;
  font-style: italic;
  text-align: start;
  opacity: var(--opacity-2);
}

.pay-me-body .pay-me-list .pay-me-row .metadata-column .region {
  font-size: calc(0.9rem * var(--font-size-scale)) !important;
  font-style: italic;
  text-align: start;
}

.pay-me-body .pay-me-list .pay-me-row .arrow {
  color: var(--inside-div-text-color);
  font-size: calc(var(--arrow-icon-size) * var(--font-size-scale)) !important;
}

@media only screen and (max-width: 1500px) {
  .pay-me-body .pay-me-list .pay-me-row {
    height: 5.5rem;
  }
}

@media only screen and (max-width: 1300px) {
  .pay-me-body .pay-me-list .pay-me-row {
    height: 5rem;
  }
}

@media only screen and (max-width: 1200px) {
  .pay-me-container .pay-me-body .middle-container {
    width: 100%;
  }

  .pay-me-body .section {
    flex-direction: column;
  }

  .pay-me-body .section .left-input {
    width: 100%;
  }

  .pay-me-body .section .left-input .content {
    padding: 1rem;
  }

  .pay-me-body .section .value-input {
    padding: 0rem;
  }

  .pay-me-body .section .left-input .content {
  }

  .pay-me-body .section .right-div {
    width: 100%;
    padding: 1rem 0rem;
  }

  .pay-me-body .buttons-div {
    width: 100%;
    flex-direction: column;
    padding: 0rem 1rem;
    margin-top: 2rem;
  }

  .pay-me-body .buttons-div .continue-button {
    margin-top: 0rem;
    width: 100%;
  }

  .pay-me-body .buttons-div .cancel-button {
    margin-top: 0rem;
    width: 100%;
    margin-bottom: 0.75rem;
  }
}

@media only screen and (max-width: 900px) {
  .pay-me-body .pay-me-list .pay-me-row {
    height: 6rem;
  }
}

@media only screen and (max-width: 600px) {
  .pay-me-container .pay-me-body .middle-container {
    width: 100%;
  }

  .pay-me-body .section {
    flex-direction: column;
  }

  .pay-me-body .section .left-input {
    width: 100%;
  }

  .pay-me-body .section .left-input .content {
    padding: 1rem;
  }

  .pay-me-body .section .value-input {
    padding: 0rem;
  }

  .pay-me-body .section .left-input .content {
  }

  .pay-me-body .section .right-div {
    padding: 1rem 0rem;
  }

  .pay-me-body .buttons-div {
    width: 100%;
    flex-direction: column;
    padding: 0rem 1rem;
    margin-top: 2rem;
  }

  .pay-me-body .buttons-div .continue-button {
    margin-top: 0rem;
    width: 100%;
  }

  .pay-me-body .buttons-div .cancel-button {
    margin-top: 0rem;
    width: 100%;
    margin-bottom: 0.75rem;
  }

  .pay-me-body .pay-me-list .pay-me-row-container {
    padding: 0;
    height: 6rem;
  }

  .pay-me-body .pay-me-list .pay-me-row {
    height: 5.5rem;
  }
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
