.result-component-container {
  margin: auto;
  width: var(--login-div-width);
  color: var(--outside-div-text-color);
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  text-align: center;
  animation: fade-in-up 0.6s;
}

.result-component-container .icon {
  font-size: calc(4rem * var(--font-size-scale));
  color: var(--outside-highlight-color);
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.result-component-container .result-title {
  text-align: center;
  font-weight: bold;
  font-size: calc(1.5rem * var(--font-size-scale));
}

.result-component-container .description {
  font-size: calc(1rem * var(--font-size-scale));
  margin: 1rem 0rem 1rem 0rem;
}

.result-component-container .continue-button {
  margin-top: 1.75rem;
  width: 100%;
  height: 3.5rem;
}

.result-component-container .back-button {
  margin-top: 1rem;
  width: 100%;
  height: 3.5rem;
}

@media only screen and (max-width: 450px) {
  .result-component-container {
    width: 100%;
    padding: 1.5rem;
  }
}
