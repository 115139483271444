.conversion-container {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.conversion-body {
  height: 100%;
  width: 100%;
  display: flex;
}

.conversion-body .conversion-div {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.conversion-body .conversion-div .bottom-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.conversion-body .conversion-div .bottom-section .filter-search-div {
  padding: 0.8rem 0rem;
}

.conversion-body .conversion-div .bottom-section .conversion-component {
  width: 49%;
  display: flex;
  flex-direction: column;
}

.conversion-body
  .conversion-div
  .bottom-section
  .conversion-component
  .from-to-div {
  display: flex;
  justify-content: space-between;
  padding: 0rem 0rem;
  width: 100%;
  height: var(--filter-height);
}

.conversion-body
  .conversion-div
  .bottom-section
  .conversion-component
  .from-to-div
  .input-group-div {
  display: flex;
  align-items: center;
  width: calc(50% - 0.5rem);
}

.conversion-body
  .conversion-div
  .bottom-section
  .conversion-component
  .from-to-div
  .input-group-div
  .label {
  font-size: calc(0.9rem * var(--font-size-scale));
  margin-right: 1rem;
}

.conversion-body
  .conversion-div
  .bottom-section
  .conversion-component
  .from-to-div
  .input-group-div
  .dropdown-filter {
}

.conversion-body .conversion-div .holding-div {
  width: 49%;
  display: flex;
  flex-direction: column;
  margin-top: calc(1rem);
}

.conversion-body .holding-div .holding-div-list {
  width: 100%;
  max-height: calc(7.5rem * 4);
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.conversion-body .holding-div .holding-div-list .card-holding {
  margin: 0rem 0.5rem 0.5rem 0.5rem;
  transition: all 0.15s ease-in-out;
  color: var(--inside-div-text-color);
  border: var(--div-border);
  min-height: 10rem;
  height: 10rem;
  position: relative;
  border-radius: var(--div-border-radius);
  background: var(--div-background);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
}

.conversion-body .holding-div .holding-div-list .card-holding .detail-column {
  display: flex;
  flex-direction: column;
  text-align: start;
  align-items: start;
  width: 35%;
  min-width: 4rem;
}

.conversion-body
  .holding-div
  .holding-div-list
  .card-holding
  .detail-column
  .from-to {
  font-weight: bold;
  text-align: start;
  width: 100%;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: calc(0.95rem * var(--font-size-scale)) !important;
}

.conversion-body
  .holding-div
  .holding-div-list
  .card-holding
  .detail-column
  .rate {
  text-align: start;
  width: 100%;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: calc(0.9rem * var(--font-size-scale)) !important;
  opacity: var(--opacity-2);
}

.conversion-body
  .holding-div
  .holding-div-list
  .card-holding
  .detail-column
  .time-left {
  font-style: italic;
  text-align: start;
  width: 100%;
  font-size: calc(0.9rem * var(--font-size-scale)) !important;
  opacity: var(--opacity-2);
  /*text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;*/
}

.conversion-body .holding-div .holding-div-list .card-holding .type-column {
  display: flex;
  display: none;
  justify-content: center;
  text-align: center;
  width: 20%;
}

.conversion-body .holding-div .holding-div-list .card-holding .buttons-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: fit-content;
}

.conversion-body
  .holding-div
  .holding-div-list
  .card-holding
  .buttons-column
  .lock-rate-operation-button {
  margin-bottom: 0.5rem;
}

.conversion-body
  .holding-div
  .holding-div-list
  .card-holding
  .detail-column
  .from-to-skeleton {
  width: 75%;
  height: 0.75rem;
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
  margin-bottom: 0.5rem;
}

.conversion-body
  .holding-div
  .holding-div-list
  .card-holding
  .detail-column
  .region-skeleton {
  width: 100%;
  height: 0.5rem;
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
}

.conversion-body
  .holding-div
  .holding-div-list
  .card-holding
  .type-column
  .type-skeleton {
  width: 100%;
  height: 0.5rem;
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
}

.conversion-body
  .holding-div
  .holding-div-list
  .card-holding
  .buttons-column
  .button-skeleton {
  width: 100%;
  height: 2rem;
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
}

.modal-input-div {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 0.5rem;
}

.modal-input-div .modal-input-label {
  font-weight: bold;
  margin-right: 2rem;
  min-width: 6rem;
}

.receipt-container .receipt-row .key {
  margin-right: 2rem;
}
.conversion-deposit-modal-body {
  display: flex;
  flex-direction: column;
}

.conversion-deposit-modal-body .qrcode {
  margin: 0 auto 0.75rem auto;
  width: 15rem;
  height: 15rem;
}

.conversion-deposit-modal-body .key-div {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.conversion-deposit-modal-body .key-div .key {
  width: 75%;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}

.conversion-deposit-modal-body .key-div .copy-key-icon {
  margin-left: 0.5rem;
  font-size: 1.5rem;
}

.conversion-deposit-modal-body .key-div .copy-success-icon {
  margin-left: 0.5rem;
  font-size: 1.5rem;
  color: var(--inside-success-color);
  animation: animate-fade 0.25s;
}

.conversion-body .bottom-section .conversion-component-container {
  padding: 1rem;
  margin-left: 0rem;
  margin-right: 0rem;
}

.conversion-body .conversion-rate-detail-container {
  padding: 1rem;
  margin-left: 0rem;
  margin-right: 0rem;
}

@media only screen and (max-width: 1500px) {
  .conversion-body .side-container {
    width: 20%;
  }

  .conversion-body .middle-container {
    width: 60%;
  }
}

@media only screen and (max-width: 1200px) {
  .conversion-body .middle-container {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .conversion-body .conversion-div {
    flex-direction: column;
  }

  /*
  .conversion-body .conversion-div .bottom-section .conversion-component {
    width: 100%;
  }

  .conversion-body .conversion-div .holding-div {
    width: 100%;
  }
 */
  .filter-search-div {
    padding: 0.8rem 0rem;
  }

  .conversion-body .holding-div .holding-div-list .card-holding {
    margin: 0rem 0rem 0.5rem 0rem;
  }
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 800px) {
  .conversion-body .conversion-div .bottom-section {
    flex-direction: column;
  }

  .conversion-body .conversion-div .bottom-section .conversion-component {
    width: 100%;
  }

  .conversion-body .conversion-div .holding-div {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
