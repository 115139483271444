:root {
}

.payment-methods-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 25rem;
}

.payment-methods-body {
  width: 100%;
  display: flex;
}

.payment-methods-body .payment-methods-padding-div {
  width: 75%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.payment-methods-body .main-title {
  text-align: start;
  font-size: calc(var(--page-title-font-size) * var(--font-size-scale));
  font-weight: bold;
  color: var(--outside-div-text-color);
  margin-bottom: var(--title-margin-bottom);
}

.payment-methods-body .payment-method-input-div {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.payment-methods-body .payment-method-info-div {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding: 0 2rem;
}

.payment-methods-body .payment-method-info-div .payment-method-info-row {
  margin-bottom: 1rem;
  color: var(--outside-div-text-color);
}

.payment-methods-body
  .payment-method-info-div
  .payment-method-info-row
  .payment-method-info-sub-title {
  font-weight: var(--font-weight-3);
  font-size: calc(0.85rem * var(--font-size-scale));
}

.payment-methods-body
  .payment-method-info-div
  .payment-method-info-row
  .payment-method-info-description {
  font-size: calc(0.85rem * var(--font-size-scale));
}

.payment-methods-body .error-message {
  margin-top: 1rem;
  color: var(--inside-danger-color);
  text-align: center;
}

.payment-methods-body .loader {
  margin-top: 4rem;
}

.payment-methods-body .input-search-div {
  display: flex;
}

.payment-methods-body .input-search-div .search-info-input {
  width: 50%;
}

.payment-methods-body .input-search-div .loader-search-info {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fade-in-up 0.6s;
}

.payment-methods-body .input-search-div .search-info-div {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  padding: 0rem 0.75rem;
  color: var(--outside-div-text-color);
}

.payment-methods-body .input-search-div .search-info-div .search-result-icon {
  font-size: calc(2rem * var(--font-size-scale));
  margin-right: 0.5rem;
}

.payment-methods-body
  .input-search-div
  .search-info-div
  .search-result-text-div {
  font-size: calc(0.9rem * var(--font-size-scale));
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 2.5rem);
}

.payment-methods-body .input-search-div .search-info-div .search-result-text {
  font-size: calc(0.9rem * var(--font-size-scale));
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.payment-methods-body .notice-div {
  margin: 1rem 2rem 0rem 2rem;
}

@media only screen and (max-width: 1500px) {
  .payment-methods-body .side-container {
    width: 20%;
  }

  .payment-methods-body .middle-container {
    width: 60%;
  }
}

@media only screen and (max-width: 1200px) {
  .payment-methods-container {
    margin-bottom: 10rem;
  }

  .payment-methods-body .payment-method-input-div {
    margin-top: 1rem;
  }

  .payment-methods-body .middle-container {
    width: 100%;
  }

  .payment-methods-body .payment-method-info-div {
    padding: 0rem;
  }

  .payment-methods-body .notice-div {
    border: none;
    text-align: start;
    margin: 0rem auto 0rem 0rem;
  }
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 450px) {
  .payment-methods-container {
    margin-bottom: 5rem;
    padding: 0rem 0.75rem;
  }

  .payment-methods-body .payment-methods-padding-div {
    width: 100%;
  }

  .payment-methods-body {
    width: 100%;
    display: flex;
  }

  .payment-methods-container .payment-methods-body .middle-container {
    width: 100%;
  }

  .payment-methods-body .input-search-div .search-info-div {
    align-items: center;
    padding-left: 1rem;
    margin-bottom: 0rem;
  }
}
