:root {
  --close-icon-size: 1.5rem;
}

.vendors-container {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.vendors-body {
  height: 100%;
  width: 100%;
  display: flex;
}

.vendors-container .vendors-body .middle-container {
  height: 100%;
}

.vendors-body .title-div {
  display: flex;
  justify-content: space-between;
  padding: 0rem 0.5rem;
  margin-bottom: var(--title-margin-bottom);
}

.vendors-body .title-div .title {
  font-size: calc(var(--page-title-font-size) * var(--font-size-scale));
  font-weight: bold;
  color: var(--outside-div-text-color);
}

.vendors-body .title-div .add-vendor-button {
  color: var(--outside-highlight-color);
  border: var(--button-button-border);
  background: none;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: calc(0.95rem * var(--font-size-scale));
}

.vendors-body .bottom-content {
  animation: fade-in-up 0.6s;
}

.vendors-body .div-cards {
  overflow: auto;
  max-height: 80vh;
}

.vendors-body .div-cards .card-vendors {
  margin: 0.5rem 0.5rem 1rem 0.5rem;
  transition: all 0.15s ease-in-out;
  color: var(--inside-div-text-color);
  border: var(--div-border);
  height: 7rem;
  position: relative;
  border-radius: var(--div-border-radius);
  background: var(--div-background);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.75rem 1rem;
  font-size: calc(0.85rem * var(--font-size-scale));
}

.vendors-body .div-cards .selected-card {
  border: none;
  color: var(--selected-text-color);
  background: var(--selected-background-color);
}

.vendors-body .div-cards .card-vendors .name-column {
  display: flex;
  flex-direction: column;
  text-align: start;
  align-items: start;
  width: 22.5%;
}

.vendors-body .div-cards .card-vendors .name-column .name {
  font-weight: var(--font-weight-3);
  font-size: calc(0.9rem * var(--font-size-scale));
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.vendors-body .div-cards .card-vendors .name-column .region {
  font-size: calc(0.9rem * var(--font-size-scale));
  opacity: var(--opacity-2);
}

.vendors-body .div-cards .card-vendors .info-column {
  display: flex;
  flex-direction: column;
  text-align: start;
  align-items: start;
  width: 25%;
}

.vendors-body .div-cards .card-vendors .info-column .email {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  width: 100%;
  opacity: var(--opacity-2);
}

.vendors-body .div-cards .card-vendors .info-column .phone {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  width: 100%;
  opacity: var(--opacity-2);
}

.vendors-body .div-cards .card-vendors .status-column {
  display: flex;
  flex-direction: column;
  text-align: start;
  align-items: start;
  width: 10%;
}

.vendors-body .div-cards .card-vendors .status {
  opacity: var(--opacity-2);
}

.vendors-body .div-cards .card-vendors .button-column {
  display: flex;
}

.vendors-body .div-cards .card-vendors .icon {
  margin-left: 0.5rem;
  font-size: calc(var(--arrow-icon-size) * var(--font-size-scale));
  cursor: pointer;
  color: var(--inside-text-color);
  display: flex;
  align-items: center;

  transition: all 0.15s ease-in-out;
}

.vendors-body .div-cards .card-vendors .icon-selected {
  color: var(--selected-text-color) !important;
}

.vendors-body .div-cards .card-vendors .show-payments-button {
  margin-right: 0.5rem;
  height: var(--small-button-height);
}

.vendors-body .div-cards .card-vendors .vendor-button {
  height: var(--small-button-height);
}

.vendors-body .skeleton-card-seperator {
  height: var(--filter-height);
}

.vendors-body .card-vendors .card-body-skeleton {
  height: 4rem;
  width: 100%;
  background-color: var(--skeleton-background-color);
}

.vendors-body .div-cards .card-vendors .metadata-column-skeleton {
  width: 30%;
  display: flex;
  align-items: center;
}

.vendors-body
  .div-cards
  .card-vendors
  .metadata-column-skeleton
  .image-skeleton {
  width: var(--image-width);
  aspect-ratio: 0.8888;
  background-color: var(--skeleton-background-color);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.vendors-body
  .div-cards
  .card-vendors
  .metadata-column-skeleton
  .text-skeleton {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  justify-content: center;
  width: 50%;
  height: 100%;
}

.div-cards
  .card-vendors
  .metadata-column-skeleton
  .text-skeleton
  .title-skeleton {
  background-color: var(--skeleton-background-color);
  height: 1rem;
  width: 100%;
  border-radius: var(--div-border-radius);
}

.div-cards
  .card-vendors
  .metadata-column-skeleton
  .text-skeleton
  .abbreviation-skeleton {
  background-color: var(--skeleton-background-color);
  margin-top: 0.5rem;
  height: 0.7rem;
  width: 80%;
  border-radius: var(--div-border-radius);
}

.vendors-body .div-cards .card-vendors .balance-column-skeleton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  height: 100%;
  width: 30%;
}

.vendors-body
  .div-cards
  .card-vendors
  .balance-column-skeleton
  .value-skeleton {
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
  height: 1rem;
  width: 75%;
}

.vendors-body
  .div-cards
  .card-vendors
  .balance-column-skeleton
  .percentage-skeleton {
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
  height: 0.9rem;
  width: 50%;
  margin-top: 0.5rem;
}

.vendors-body .vendor-detail-div {
  border: var(--div-border);
  border-radius: var(--div-border-radius);
  background: var(--div-background);
  color: var(--inside-div-text-color);
  margin: 0rem 1rem;
  padding: 1rem;
  min-height: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: fade-in-up 0.6s;
  margin-top: calc(
    var(--title-margin-bottom) +
      (((var(--page-title-font-size) + 0.4rem) * var(--font-size-scale))) +
      0.8rem
  );
  font-size: calc(0.85rem * var(--font-size-scale));
}

.vendors-body .vendor-detail-div .loader {
  width: 3rem !important;
  height: 3rem !important;
}

.vendors-body .vendor-detail-div .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vendors-body .vendor-detail-div .header .title {
  font-weight: bold;
  font-size: calc(1rem * var(--font-size-scale));
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - var(--close-icon-size));
}

.vendors-body .vendor-detail-div .header .close-icon {
  cursor: pointer;
  font-size: var(--close-icon-size);
}

.vendors-body .vendor-detail-div .body {
  padding: 1rem 0;
  font-style: italic;
}

.vendors-body .vendor-detail-div .body .list-payment-methods {
  overflow: auto;
  max-height: 14rem;
}

.vendors-body .vendor-detail-div .body .list-payment-methods .headers {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 3rem;
  margin: 0.5rem 0rem 0.25rem 0rem;
}

.vendors-body
  .vendor-detail-div
  .body
  .list-payment-methods
  .payment-method-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-style: normal;
  height: 3rem;
  border: var(--inside-highlight-color) 1px solid;
  border-radius: var(--div-border-radius);
  margin: 0.5rem 0rem;
  padding: 0.5rem;
}

.vendors-body .vendor-detail-div .body .list-payment-methods .name-header {
  width: 32%;
  text-align: start;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}

.vendors-body .vendor-detail-div .body .list-payment-methods .type-header {
  width: 20%;
  text-align: start;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}

.vendors-body .vendor-detail-div .body .list-payment-methods .icon-header {
  width: 1.5rem;
}

.vendors-body .vendor-detail-div .body .list-payment-methods .button-header {
  width: 3.75rem;
}

.vendors-body
  .vendor-detail-div
  .body
  .list-payment-methods
  .payment-method-row
  .icon {
  font-size: 1.2rem;
  cursor: pointer;
}

.vendors-body
  .vendor-detail-div
  .body
  .list-payment-methods
  .payment-method-row
  .pay-button {
  width: 3.75rem;
}

.vendors-body .vendor-detail-div .footer .detail-button {
  width: 100%;
}

.vendors-body .vendor-detail-div-history {
  border: var(--div-border);
  border-radius: var(--div-border-radius);
  background: var(--div-background);
  color: var(--inside-div-text-color);
  margin: 0rem 1rem;
  padding: 1rem;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: calc(
    var(--title-margin-bottom) +
      (((var(--page-title-font-size) + 0.4rem) * var(--font-size-scale))) +
      0.8rem
  );
  animation: fade-in-up 0.6s;
  font-size: calc(0.85rem * var(--font-size-scale));
}

.vendors-body .vendor-detail-div-history .filter-div {
  display: flex;
  justify-content: center;
  font-style: normal;
  margin-bottom: 0.5rem;
}

.vendors-body .vendor-detail-div-history .filter-div .filter-options {
  display: flex;
}

.vendors-body
  .vendor-detail-div-history
  .filter-div
  .filter-options
  .option-div {
  margin-right: 0.75rem;
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 1rem;
  min-width: 2.5rem;
  border: 1px solid transparent;
  transition: all 0.1s ease-in-out;
}

.vendors-body
  .vendor-detail-div-history
  .filter-div
  .filter-options
  .option-div-picked {
  border: 1px solid var(--inside-highlight-color);
}

.vendors-body .vendor-detail-div-history .loader {
  width: 3rem !important;
  height: 3rem !important;
}

.vendors-body .vendor-detail-div-history .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vendors-body .vendor-detail-div-history .header .title {
  font-weight: bold;
  font-size: 1.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - var(--close-icon-size));
}

.vendors-body .vendor-detail-div-history .header .close-icon {
  cursor: pointer;
  font-size: 1.5rem;
}

.vendors-body .vendor-detail-div-history .body {
  padding: 1rem 0;
  font-style: italic;
}

.vendors-body .vendor-detail-div-history .body .list-payments {
  overflow: auto;
  max-height: 14rem;
}

.vendors-body .vendor-detail-div-history .body .list-payments .headers {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 3rem;
  margin: 0.5rem 0rem 0.25rem 0rem;
}

.vendors-body .vendor-detail-div-history .body .list-payments .to-column {
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  width: 29%;
}

.vendors-body .vendor-detail-div-history .body .list-payments .date-column {
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  width: 28%;
}

.vendors-body .vendor-detail-div-history .body .list-payments .status-column {
  width: 15%;
  text-align: start;
}

.vendors-body
  .vendor-detail-div-history
  .body
  .list-payments
  .status-column
  .status {
  width: 2rem;
  display: flex;
  justify-content: center;
  border-radius: 0.25rem;
  font-weight: bold;
}

.vendors-body .vendor-detail-div-history .body .list-payments .amount-column {
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 22%;
}

.vendors-body .vendor-detail-div-history .body .list-payments .payments-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-style: normal;
  height: 3rem;
  border: var(--inside-highlight-color) 1px solid;
  border-radius: var(--div-border-radius);
  margin: 0.5rem 0rem;
  padding: 0.5rem;
  cursor: pointer;
}

.vendors-body .vendor-detail-div-history .footer .detail-button {
  width: 100%;
}

@media only screen and (max-width: 1500px) {
  .vendors-body .vendor-detail-div {
    padding: 0.75rem;
  }

  .vendors-body
    .vendor-detail-div
    .body
    .list-payment-methods
    .headers
    .type-header {
    display: none;
  }

  .vendors-body
    .vendor-detail-div
    .body
    .list-payment-methods
    .payment-method-row
    .name-header {
    width: 40%;
    text-align: start;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
  }

  .vendors-body
    .vendor-detail-div
    .body
    .list-payment-methods
    .payment-method-row
    .type-header {
    display: none;
  }
}

@media only screen and (max-width: 1300px) {
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
  :root {
    --padding-cards: 0.75rem;
  }

  .vendors-container {
  }

  .vendors-container .vendors-body .middle-container {
    width: 100%;
  }

  .vendors-body .title-div {
    padding: 0rem;
  }

  .vendors-body .title-div .add-vendor-button {
    padding-right: 0rem;
  }

  .vendors-body .div-cards {
    max-height: unset;
  }

  .vendors-body .div-cards .card-vendors {
    width: 100% !important;
    margin: 0.4em 0rem;
    transition: none;
  }

  .vendors-body .div-cards .card-vendors .content {
    padding: 0.75rem 1rem;
  }

  .vendors-body .div-cards .card-vendors .name-column {
    width: 42.5%;
  }

  .vendors-body .div-cards .card-vendors .name-column .name {
    font-size: 1.1rem;
  }

  .vendors-body .div-cards .card-vendors .name-column .region {
  }

  .vendors-body .div-cards .card-vendors .phone-column {
    width: 20%;
  }

  .vendors-body .div-cards .card-vendors .email-column {
    width: 35%;
  }

  .vendors-body .skeleton-card-seperator {
    display: none;
  }

  .vendors-body .div-cards .card-vendors .metadata-column-skeleton {
    width: 43%;
  }

  .vendors-body .div-cards .card-vendors .balance-column-skeleton {
    width: 22%;
  }
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
